import { Disclosure } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { userNavigation } from 'src/data/navigation';
import { GetCurrentUser, UserContext } from 'src/providers/UserProvider';
import Notifications from '../Notifications';

const MobileNavigationUserContent = (props) => {
    const { user } = useContext(UserContext);
    console.log('user', user);
    
    return (
        <div className="pt-4 pb-3 border-t border-gray-700">
            <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                    <>
                        {console.log(user)}
                        {user?.photoUrl === undefined ? (
                            <UserCircleIcon className="h-8 w-8 rounded-full text-gray-400 hover:text-white" />
                        ) : (
                            <img
                                className="h-10 w-10 rounded-full"
                                src={user?.photoUrl}
                                alt={user?.displayName}
                            />
                        )}
                    </>
                </div>
                <div className="ml-3">
                    <div className="text-base font-medium text-white">
                        {user?.displayName}
                    </div>
                    <div className="text-sm font-medium text-gray-400">
                        {user?.email}
                    </div>
                </div>
                <div className="ml-auto flex-shrink-0">
                    <Notifications count={1} />
                </div>
            </div>
            <div className="mt-3 px-2 space-y-1">
                {userNavigation.map((item) => (
                    <NavLink
                        key={item.name}
                        to={item.href}
                        className={(isActive) =>
                            classNames(
                                'block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700'
                            )
                        }
                    >
                        <Disclosure.Button>{item.name}</Disclosure.Button>
                    </NavLink>
                ))}
            </div>
        </div>
    );
};

export default MobileNavigationUserContent;
