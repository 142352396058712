import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../auth/firebase';

export const UserContext = React.createContext(undefined);

const UserProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [user, setUser] = useState(null);

    useEffect(() => {
        return auth.onAuthStateChanged((result) => {
            if (result !== null) {
                setUser(result);
                if (location.pathname === '/login') {
                    navigate('/', { replace: true });
                }
            } else {
                navigate('/login', { replace: true });
            }
        });
    }, []);

    return (
        <UserContext.Provider
            value={{
                user: user,
                setUser: setUser,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const GetCurrentUser = () => {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('GetCurrentUser must be used within a UserProvider');
    }
    return context.user;
};

export default UserProvider;
