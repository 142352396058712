import { SignOutCurrentUser } from "src/auth/firebase";

export const navigation = [
    { name: "Dashboard", href: "/", current: true },
    { name: "Content", href: "/content", current: false },
    { name: "Users", href: "/users", current: false },
];

export const userNavigation = [
    { name: 'My Profile', href: '/user/profile' },
    { name: 'Sign out', href: '#', callback: SignOutCurrentUser },
];