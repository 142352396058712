import React, { useEffect, useState } from 'react';
import AppBar from '../../components/AppBar';
import Header from '../../components/Header';
import { AnimatePresence, motion } from 'framer-motion';
import UserProvider from '../../providers/UserProvider';
import BackendProvider from '../../providers/BackendProvider';
import NotificationProvider from 'src/providers/NotificationProvider';
import NotificationPanel from 'src/components/Notifications/NotificationPanel';
import { useNavigate } from 'react-router-dom';
import { auth } from 'src/auth/firebase';
import Loading from 'src/components/Loading';
import BottomBanner from 'src/components/BottomBanner';

const pageVariants = {
    initial: {
        opacity: 0,
    },
    in: {
        opacity: 1,
    },
    out: {
        opacity: 0,
    },
};

const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3,
};

const Application = ({ children }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [bannerOpen, setBannerOpen] = useState(true);
    const user = auth.currentUser;

    const banner =
        user !== null && !user.emailVerified ? (
            <BottomBanner
                title="Please verify your email address."
                titleShort="Please verify your email address."
                actionTitle="Dismiss"
                actionHref="#"
                open={bannerOpen}
                setOpen={setBannerOpen}
                type="warning"
            />
        ) : (
            ''
        );

    useEffect(() => {
        let active = true;

        if (active) {
            const subscribe = auth.onAuthStateChanged((user) => {
                if (user === null) {
                    setLoading(true);
                    navigate('/login');
                } else {
                    setLoading(false);
                }
            });
            return () => {
                active = false;
                subscribe();
            };
        }
    }, []);

    if (loading) {
        return <Loading showText={true} />;
    }

    return (
        <AnimatePresence>
            <UserProvider>
                <BackendProvider>
                    <NotificationProvider>
                        <div className="min-h-full">
                            <header className="bg-white shadow z-10 sticky top-0">
                                <AppBar />
                            </header>
                            <motion.div
                                initial="initial"
                                animate="in"
                                exit="out"
                                variants={pageVariants}
                                transition={pageTransition}
                            >
                                <main className="relative">
                                    {children}
                                </main>
                            </motion.div>

                            <NotificationPanel />

                            {banner}
                        </div>
                    </NotificationProvider>
                </BackendProvider>
            </UserProvider>
        </AnimatePresence>
    );
};

export default Application;
