import React from 'react';
import Breadcrumbs from '../Breadcrumbs';

const Header = ({ title, actions = null }) => {
    return (
        <div className="bg-white shadow w-full">
            <div className="flex max-w-3xl lg:max-w-7xl mx-auto py-1 px-2 md:px-0 lg:px-8 pt-1 sm:pt-0 lg:pt-2 items-center content-center justify-center">
                <div className="flex-1">
                    <div className="pb-4 sm:pb-2 hidden sm:block">
                        <Breadcrumbs display={true} />
                    </div>
                    
                    <h1 className="text-3xl lg:text-4xl leading-6 font-bold text-gray-900">
                        {title}
                    </h1>
                </div>
                {actions && (
                    <div className="flex m-2 lg:ml-4 content-end justify-end">
                        {actions}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;
