import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
// Pages
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import ContentPage from './pages/ContentPage';
import UsersPage from './pages/UsersPage';
// Styles
import './assets/css/main.css';
import { UserProfilePage } from './pages/UserProfilePage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            {/* Login Pages */}
            <Route path="/login" element={<LoginPage />} />
            {/* Application Pages (Authenticated) */}
            <Route path="/">
                <Route index element={<HomePage />} />
                <Route path="content" element={<ContentPage />} />
                <Route path="users" element={<UsersPage />} />
                <Route path="user">
                    <Route index element={<Navigate replace to="/user/profile" />} />
                    <Route path="profile" element={<UserProfilePage />} />
                </Route>
            </Route>


            {/* Utility Pages */}
            {/* <Route exact path="/recover"><RecoverPage /></Route> */}
            {/* <Route exact path="/failure"><FailPage /></Route> */}
        </Routes>
    </BrowserRouter>
);
