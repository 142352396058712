import { initializeApp } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

let firebaseConfig = null;

if (process.env.NODE_ENV === 'development') {
    firebaseConfig = {
        apiKey: "AIzaSyA_WKq1LDg7VoC22USv6ZvR3T-cWbWjrBY",
        authDomain: "hermesapp-alpha.firebaseapp.com",
        projectId: "hermesapp-alpha",
        storageBucket: "hermesapp-alpha.appspot.com",
        messagingSenderId: "325377435194",
        appId: "1:325377435194:web:6dee1f4ad5f4a35f17b703",
        measurementId: "G-RMCMV735B9"
    };
} else {
    firebaseConfig = {
        apiKey: "AIzaSyA_WKq1LDg7VoC22USv6ZvR3T-cWbWjrBY",
        authDomain: "hermesapp-alpha.firebaseapp.com",
        projectId: "hermesapp-alpha",
        storageBucket: "hermesapp-alpha.appspot.com",
        messagingSenderId: "325377435194",
        appId: "1:325377435194:web:6dee1f4ad5f4a35f17b703",
        measurementId: "G-RMCMV735B9"
    };
}

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const SignOutCurrentUser = (e) => {
    e.preventDefault();
    signOut(auth);
};
