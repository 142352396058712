import { Menu, Transition } from '@headlessui/react';
import {
    CheckIcon,
    ChevronDownIcon,
    LinkIcon,
    PencilIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import DropdownButton from 'src/components/UI/DropdownButton';

const ContentActionMenu = (props) => {
    return (
        <div className="flex lg:ml-4">
            <DropdownButton title={(<>Create</>)} />
        </div>
    );
};

ContentActionMenu.propTypes = {};

export default ContentActionMenu;
