import React from 'react';
import classNames from 'classnames';
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    XCircleIcon,
    XIcon,
} from '@heroicons/react/solid';

const NotificationItem = ({ item, removeItem }) => {
    return (
        <li key={item.id}>
            <div className="group relative flex items-center py-6 px-5">
                <span className="-m-1 block flex-1 p-1">
                    <div
                        className="absolute inset-0 group-hover:bg-gray-50"
                        aria-hidden="true"
                    />
                    <div className="relative flex min-w-0 flex-1 items-center">
                        <span className="relative inline-block flex-shrink-0">
                            {item.imageUrl ? (
                                <>
                                    <img
                                        className="h-10 w-10 rounded-full"
                                        src={item.imageUrl}
                                        alt=""
                                    />
                                    <span
                                        className={classNames(
                                            item.type === 'success' &&
                                                'bg-green-400',
                                            item.type === 'warning' &&
                                                'bg-orange-400',
                                            item.type === 'error' &&
                                                'bg-red-400',
                                            item.type === '' && 'bg-gray-400',
                                            'absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white'
                                        )}
                                        aria-hidden="true"
                                    />
                                </>
                            ) : (
                                <>
                                    {item.type === 'success' && (
                                        <CheckCircleIcon className="h-10 w-10 bg-green-400 text-white rounded-full" />
                                    )}
                                    {item.type === 'warning' && (
                                        <ExclamationCircleIcon className="h-10 w-10 bg-orange-400 text-white rounded-full" />
                                    )}
                                    {item.type === 'error' && (
                                        <XCircleIcon className="h-10 w-10 bg-red-400 text-white rounded-full" />
                                    )}
                                </>
                            )}
                        </span>
                        <div className="ml-4 truncate">
                            <p className="truncate text-sm font-medium text-gray-900">
                                {item.title}
                            </p>
                            <p className="truncate text-sm text-gray-500">
                                {item.body}
                            </p>
                        </div>
                    </div>
                </span>
                <div className="relative ml-2 inline-block flex-shrink-0 text-left">
                    <button
                        onClick={() => removeItem(item.id)}
                        className="group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        <span className="sr-only">Open options menu</span>
                        <span className="flex h-full w-full items-center justify-center rounded-full">
                            <XIcon
                                className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                            />
                        </span>
                    </button>
                </div>
            </div>
        </li>
    );
};

export default NotificationItem;
