import React from 'react';
import iconProd from '../../assets/images/logo2.png';
import iconDev from '../../assets/images/logo2.png';

const HeaderLogo = (props) => {
    const logo = process.env.NODE_ENV === 'production' ? iconProd : iconDev;

    return (
        <>
            <img
                className="block lg:hidden h-4 w-auto rounded"
                src={logo}
                alt="TTV"
            />
            <img
                className="hidden lg:block h-6 w-auto rounded"
                src={logo}
                alt="TTV"
            />
        </>
    );
};

export default HeaderLogo;
