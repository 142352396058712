import React, { useState } from 'react';
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/solid';

const BottomBanner = ({
    title,
    titleShort,
    actionTitle,
    actionHref,
    open,
    setOpen,
    type
}) => {

    let mainClass = null;
    let icon = null;
    let iconClass = null;
    switch(type) {
        case 'success':
            mainClass = 'bg-green-600';
            iconClass = 'bg-green-700';
            icon = <CheckCircleIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
            />;
            break;
        case 'warning':
            mainClass = 'bg-orange-600';
            iconClass = 'bg-orange-700';
            icon = <ExclamationCircleIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
            />;
            break;
        case 'error':
            mainClass = 'bg-red-600';
            iconClass = 'bg-red-700';
            icon = <XCircleIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
            />;
            break;
        default:
            mainClass = 'bg-blue-600';
            iconClass = 'bg-blue-700';
            icon = <SpeakerphoneIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
            />;
    }

    return (
        <>
            {open && (
                <div className="fixed inset-x-0 bottom-0">
                    <div className={mainClass}>
                        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                            <div className="flex items-center justify-between flex-wrap">
                                <div className="w-0 flex-1 flex items-center">
                                    <span className={classNames(
                                        iconClass,
                                        "flex p-2 rounded-lg"
                                    )}>
                                        {icon}
                                    </span>
                                    <p className="ml-3 font-medium text-white truncate">
                                        <span className="md:hidden">
                                            {titleShort}
                                        </span>
                                        <span className="hidden md:inline">
                                            {title}
                                        </span>
                                    </p>
                                </div>
                                <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                                    <NavLink
                                        to={actionHref}
                                        onClick={() => setOpen(false)}
                                        className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-800 bg-white hover:bg-blue-50"
                                    >
                                        {actionTitle}
                                    </NavLink>
                                </div>
                                <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                                    <button
                                        type="button"
                                        onClick={() => setOpen(false)}
                                        className="-mr-1 flex p-2 rounded-md hover:bg-gray-300 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                                    >
                                        <span className="sr-only">{actionTitle}</span>
                                        <XIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default BottomBanner;
