import React, { useContext } from 'react';
import { BellIcon } from '@heroicons/react/outline';
import { NotificationContext } from 'src/providers/NotificationProvider';

const Notifications = (props) => {
    const { notifications, panelOpen, setPanelOpen } =
        useContext(NotificationContext);

    const togglePanel = (panelOpen, setPanelOpen) => {
        setPanelOpen(!panelOpen);
    };

    return (
        <nav className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
            <>
                <button onClick={(e) => togglePanel(panelOpen, setPanelOpen)}>
                    <span className="sr-only">View notifications</span>
                    <span className="relative inline-block">
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                        {notifications.length > 0 && (
                            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                {notifications.length}
                            </span>
                        )}
                    </span>
                </button>
            </>
        </nav>
    );
};

export default Notifications;
