import React from 'react';
import { HomeIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { NavLink, useLocation } from 'react-router-dom';

const pages = [
    { name: 'Projects', href: '#', current: false },
    { name: 'Project Nero', href: '#', current: true },
];

export const Breadcrumbs = ({ display }) => {
    let path = "";
    const location = useLocation();
    const pages =
        location
        .pathname.split('/')
        .filter((f) => f !== '')
        .map(name => {
            path = `${path}/${name}`;
            return {
                name: name,
                href: path,
                current: path === location.pathname
            };
        });

    return (
        <>
            {display && (
                <nav className="flex" aria-label="Breadcrumbs">
                    <ol role="list" className="flex items-center space-x-1">
                        <li>
                            <div>
                                <NavLink
                                    to="/"
                                    className="text-gray-400 hover:text-gray-500"
                                >
                                    <HomeIcon
                                        className="flex-shrink-0 h-5 w-5"
                                        aria-hidden="true"
                                    />
                                    <span className="sr-only">Home</span>
                                </NavLink>
                            </div>
                        </li>
                        {pages.map((page) => (
                            <li key={page.name}>
                                <div className="flex items-center">
                                    <ChevronRightIcon
                                        className="flex-shrink-0 h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                    <NavLink
                                        to={page.href}
                                        className="ml-1 text-sm font-medium text-gray-500 hover:text-gray-700 capitalize"
                                        aria-current={
                                            page.current ? 'page' : undefined
                                        }
                                    >
                                        {page.name}
                                    </NavLink>
                                </div>
                            </li>
                        ))}
                    </ol>
                </nav>
            )}
        </>
    );
};

export default Breadcrumbs;
