import classNames from 'classnames';
import React, { useState } from 'react';

const VerticalTabs = ({ tabs, forceSelected = false }) => {
    const [data, setData] = useState(tabs);

    const switchTab = (e, name) => {
        e.preventDefault();
        setData(
            data.map((item) => {
                if (item.name === name) {
                    if (item.current) {
                        if (!forceSelected) {
                            item.current = false;
                        } 
                    } else {
                        item.current = true;
                        item.callback && item.callback();
                    }
                } else {
                    item.current = false;
                }
                return item;
            })
        );
    };

    return (
        <div className="pb-4 space-y-1">
            {data.map((item) => (
                <a
                    key={item.name}
                    href="#"
                    onClick={(e) => switchTab(e, item.name)}
                    className={classNames(
                        item.current
                            ? 'bg-gray-200 text-gray-700 font-semibold'
                            : 'text-gray-700 hover:bg-gray-50',
                        'group flex items-center px-3 py-2 text-sm rounded-md'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                >
                    {item.icon &&
                        <item.icon
                            className={classNames(
                                item.current
                                    ? 'text-gray-500 font-bold'
                                    : 'text-gray-300 group-hover:text-gray-500',
                                'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                            )}
                            aria-hidden="true"
                        />
                    }
                    
                    <span className="truncate">{item.name}</span>
                </a>
            ))}
        </div>
    );
};

VerticalTabs.propTypes = {};

export default VerticalTabs;
