import Application from '../../layout/Application';
import React from 'react';
import Feed from '../../components/Feed';

export const HomePage = (props) => {
    return (
        <Application>
            <div className="max-w-7xl mx-auto py-4 lg:py-6 px-2 sm:px-4 lg:px-8">
                <Feed key="home-page" />
            </div>
        </Application>
    );
};

export default HomePage;
