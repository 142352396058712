import React from 'react';
import Application from 'src/layout/Application';

const UsersPage = (props) => {
    return (
        <Application
            displayHeader={true}
            title="Users"
        >
            <h1>Users</h1>
        </Application>
    );
};

export default UsersPage;
